import React, { FC } from "react"
import classNames from "classnames";

interface InputGroupProps{
    name: string;
    label?: string;
    required?: boolean;
    children?: any;
    formik: any;

    className?: string;
    placeholder?: string;
    type?: "text" | "email" | "number" | "tel"

}

const InputGroup:FC<InputGroupProps> = ({
    name,
    label,
    required,
    children,
    formik,

    className,
    placeholder,
    type,
}) => {
  
  
  return (
    <div className={classNames(
        'input-group',
        className && className
        )}>
        <label 
            htmlFor={name} 
            className={`${required && "required"}`}
        >
            {label}
        </label>
        {type && children === undefined && 
            <input 
                required={required} 
                onChange={formik.handleChange} 
                type={type} 
                name={name}
                id={name}
                value={formik.values[name]} 
                className="input-primary" 
                placeholder={placeholder || ""}
            />
        }
        {children}
        {formik.touched[name] && formik.errors[name] ? (
            <p className="text-sm text-error mt-1">{formik.errors[name]}</p>
        ) : null}
    </div>
  )
}

export default InputGroup