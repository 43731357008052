import React, { FC } from 'react';
import "../styles/cgamini.css";
import graph from "../images/icons/Graph.svg";
import character from "../images/icons/Character.svg";
import curveBg from "../images/icons/CurveCgaMini.svg";
import Button from '../utils/button/Button';

interface AddToWaitingListProps{
    toggleModal: ()=>void;
}

const AddToWaitingList: FC<AddToWaitingListProps> = ({
    toggleModal
}) => {

    function handleClickModal(){
        toggleModal();
        if(typeof window !== undefined){
            window.gtag("event", "click", { send_to: "AW-10886923757/hvUACO3IzrQDEO2Lpcco"});
        }
    }

    return(
        <div className="container-xl add-to-waiting">
            <img src={curveBg} className="curve" alt="background curve"/>
            <div className="row">
                <h2 className="text-muted text-center col-sm-12 col-md-6 offset-md-3">
                    Nástroj na
                    automatizaci SEO
                </h2>
                <div className="card card-spacey col-sm-12 col-md-8 offset-md-2 text-center position-relative">
                    <img className="cga-illustration" src={graph} alt="graph" />
                    <img className="cga-illustration z-0" src={character} alt="character" />
                    <h5 className="">
                    Spustili jsme Share Of Voice
                    </h5>
                    <p className="text-center fill-sky-500">
                        Spuštění celého nástroje se blíží, ale my jsme se rozhodli nabídnout <b>ochutnávku</b> v podobě nejpřísnější metriky všech SEO reportů - <b>Share of Voice</b>. Pokud chceš být mezi prvními, kteří se s námi vydají na cestu k <b>SEO (r)evoluci</b>, klikni na tlačítko níže a registruj se. S registrací dostaneš přístupy na analýzu a <b>2000 klíčových slov ZDARMA</b>. 💜
                    </p>
                    <Button
                        id="btn-cta-subscribe"
                        onClick={handleClickModal}
                        design='cta'
                        className='btn mt-6 btn-cta normal-case'
                    >
                        Vyzkoušet Share of Voice
                    </Button>
                </div>
            </div>
            {}
        </div>
    )
}

export default AddToWaitingList;