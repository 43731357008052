import React, {FC, ReactChild, useEffect, useRef, useState} from 'react';
import "../styles/slider.css";
import arrow from "../images/icons/ArrowMuted.svg";

interface SliderProps {
    className?: string;
    children?: ReactChild;
    interval?: number;
    id?: string;
}

const Slider : FC<SliderProps> = ({
  className,
  children,
  interval : intervalProp,
  id
}) => {

    
    const [current, setCurrent] = useState<number>(0);
    // const [currentInterval, setCurrentInterval] = useState<any>(null);
    
    const randomId : string = (Math.random() + 1).toString(36).substring(2);

    const containerRef = useRef(null);
    
    useEffect(() => {
        const length = containerRef.current?.children.length;
        if(length&&length>2){
            for(let i=1;i<length-2;i++){
              containerRef.current.children[i].classList.add("hidden");
            }
        }
        setCurrent(0);
    }, [children, containerRef])

    useEffect(()=>{
      const interval = setInterval(() => {
        updatePage(current+1);
      }, intervalProp);

      return () => clearInterval(interval);
  }, [current, intervalProp]);

    function updatePage(nextPage:number):void{
        let next : number = nextPage;
        const loopLength : number = containerRef.current?.children.length;
        if(containerRef.current&&loopLength&&loopLength>2){
            if(nextPage>=loopLength-2){
              next = 0;
            }else if(nextPage<0){
              next = loopLength-3;
            }else{
              next = nextPage;
            }
            containerRef.current.children[next].classList.toggle("hidden");
            containerRef.current.children[current].classList.toggle("hidden");
            setCurrent(next);
        }    
    }

    return (
      <div id={id ||`slider-${randomId}`} className={"slider "+(className?className:"")} ref={containerRef}>
        {children}
        <button
            className="btn prev btn-outline btn-muted inline"
            onClick={()=>updatePage(current-1)}
          >
            <img src={arrow} alt="arrow prev" />
          </button>
          <button
            className="btn next btn-outline btn-muted inline"
            onClick={()=>updatePage(current+1)}
          >
            <img src={arrow} alt="arrow next" />
          </button>
      </div>
    )
  }
  export default Slider;