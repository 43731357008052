import React, {FC, useState} from 'react';
import "../styles/cta-block.css";
import arrow from "../images/icons/Arrow.svg";
import curve from "../images/icons/Curve.svg";

interface CtaProps{
    subscribe: (email:string)=>void
}

const CtaBlock:FC<CtaProps> = ({
    subscribe
}) => {

    const [email, setEmail] = useState<string>("");

    // function signInNewsletter(){
    //     alert(email+" registrován.");
    // }

    function handleClickEmail(){
        if(typeof window !== undefined){
            window.gtag("event", "click", { send_to: "AW-10886923757/d8zLCPmOmbQDEO2Lpcco"})
        }
    }

    function handleClickEmailSend(){
        subscribe(email)
        if(typeof window !== undefined){
            window.gtag("event", "click", { send_to: "AW-10886923757/SLahCLaHmbQDEO2Lpcco"})
        }
    }

    return (
        <div className="container-xl cta-block">
            <div className="row position-relative">
                <div className="card card-spacey card-cta text-center position-relative">
                    <div className='heading'>
                        <h4>Ještě minutu a šetříš hodiny</h4>
                    </div>
                    <div className="input-group-out">
                        <div className="input-group">
                            <input type="text" name="email" placeholder="Tvoje e-mailová adresa" value={email} onClick={handleClickEmail} onChange={(e)=>setEmail(e.target.value)} />
                            <button id="btn-email-subscribe" className="btn btn-primary" type="submit" onClick={handleClickEmailSend}>
                                <img
                                    src={arrow}
                                    alt="Odeslat"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <img
                    className="swoosh"
                    src={curve} 
                    alt="swoosh curve"
                />
            </div>
        </div>
    )
  }
  export default CtaBlock;