import React from 'react';
import iconFacebook from "../images/icons/logos/Icon-Facebook-colorful.svg";
import iconTwitter from "../images/icons/logos/Icon-Twitter-colorful.svg";
import iconLinkedIn from "../images/icons/logos/Icon-LinkedIn-colorful.svg";
import iconInstagram from "../images/icons/logos/Icon-Instagram-colorful.svg";

const SocialsBlock = () => {

    return (
        <div id="socials" className="container-fluid container-padding-left container-padding-right block-socials text-center">
            <div className="row position-relative">
                <div className="row block-socials-inner">
                    <a className="text-center icon-single" href="https://www.facebook.com/KeywordEater/">
                        <img src={iconFacebook} alt="icon copy" style={{width: "48px"}} />
                    </a>
                    <a className="text-center icon-single" href="https://www.instagram.com/keywordeater/">
                        <img src={iconInstagram} alt="icon copy" style={{width: "48px"}} />
                    </a>
                    <a className="text-center icon-single" href="https://twitter.com/KeywordEater">
                        <img src={iconTwitter} alt="icon copy" style={{width: "48px"}} />
                    </a>
                    <a className="text-center icon-single" href="https://www.linkedin.com/company/keyword-eater">
                        <img src={iconLinkedIn} alt="icon copy" style={{width: "48px"}} />
                    </a>
                </div>
            </div>
        </div>
    )
  }
  export default SocialsBlock;