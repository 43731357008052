import { Helmet } from "react-helmet"
import React, { useState } from "react"
import CtaBlock from "../components/CtaBlock";
import Footer from "../components/Footer";
import "../styles/bootstrap-grid.min.css";
import "../styles/bootstrap-reboot.min.css";
import "../styles/general.css";
import logoMall from "../images/icons/logos/Logo-Mall.svg";
import logoHeureka from "../images/icons/logos/Logo-Heureka.svg";
import logoSkrz from "../images/icons/logos/Logo-Skrz.svg";
import star from "../images/icons/Star.svg";
import swooshCurve from "../images/icons/Curve.svg";
import iconMetrics from "../images/icons/Icon-Metrics.svg";
import iconProjects from "../images/icons/Icon-Projects.svg";
import iconGame from "../images/icons/Icon-Game.svg";
import { StaticImage } from "gatsby-plugin-image";
import scrollHint from "../images/icons/ScrollHint.svg";
import Slider from "../components/Slider";
import AddToWaitingList from "../components/AddToWaitingList";
import SocialsBlock from "../components/SocialsBlock";
import NavBar from "../components/NavBar";
import ModalRegister from "../components/ModalRegister";
import { navigate } from "gatsby";

// markup
const IndexPage = ({location}) => {


  const isRegister = location.search === "?register";
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const [modalRegister, setModalRegister] = useState<boolean>(isRegister);
  const [email, setEmail] = useState<string>("");

  function handleModalToggle(){
    // navigate('/edit');
    if(modalRegister){
      navigate('/', { replace: true });
    }else{
      navigate('?register', { replace: true });
    }
    setModalRegister(!modalRegister);
  }

  // useEffect(() => {
  //   setInterval(() => testimonialsLoop(activeTestimonial+1), 5000);
  // }, [])

  // useEffect(() => {
  //   setTestimonialInterval(setInterval(() => testimonialsLoop(activeTestimonial+1), 5000));
  //   return () => {
  //     setTestimonialInterval(null);
  //   }
  // }, [])

  // function load(){
  //   if(isInitial){
  //     fetch("/icons/logos/banner.txt")
  //     .then(r => r.text())
  //     .then(text => {
  //         console.log(text);
  //     });
  //     setIsInitial(false);
  //   }
  //   // console.log("%cKeyword Eater", "color: #2D0E6B; font-family: Poppins; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
  // }
  
  return (
    <main 
    // onMouseEnter={load} 
    className="body">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyword Eater - Šetříme stovky hodin SEO specialistům</title>
      </Helmet>
      <NavBar />
      <AddToWaitingList
        toggleModal={handleModalToggle}
      />
      <img src={scrollHint} className="mobile-scroll-hint" alt="scroll hint" />
      {/* reference */}
      <div className="container-xl reference">
        <div className="row card card-secondary">
          <div className="col-sm-3 col-md-2">
            <img src={logoMall} alt="logo Mall" />
          </div>
          <div className="col-sm-3 col-md-2">
            <img src={logoHeureka} alt="logo Heuréka" />
          </div>
          {/* <div className="col-sm-3 col-md-2">
            <img src={logoSkrz} alt="logo Evisions" />
          </div> */}
        </div>
      </div>
      {/* feature card stack */}
      <div className="container-fluid feature-card-stack container-padding-left" id="functions" style={{scrollMarginTop: "120px"}}>
        <div className="row position-relative">
          <div className="card card-spacey card-stackable">
            <p className="text-muted">
              ANALÝZY
            </p>
            <h3>
              Hodinová analýza
              mrknutím oka
            </h3>
            <p><b>Představ si, že máš před sebou analýzu, která ti zabere 15 hodin.</b>. Termín je zítra. Cítíš se ve stresu? Nemusíš! Ať už se jedná o CGA, SOV nebo další. S naším Žroutem <b>máš analýzu za pár vteřin</b> a výsledky ti naservírujeme v přehledných dashboardech.</p>
          </div>
          <div className="card card-stackable card-stackable-layered">
            <img src={star} alt="star" />
            <div className="placeholder" style={{height: "200px", width: "100%", marginTop: "48px"}} ></div>
            <div className="placeholder" style={{height: "32px", width: "100%", marginTop:"24px"}} ></div>
          </div>
          <div className="card card-stackable card-stackable-layered">
            <img src={star} alt="star" />
            <div className="placeholder" style={{height: "200px", width: "100%", marginTop: "50px"}} ></div>
            <div className="placeholder" style={{height: "32px", width: "100%", marginTop:"24px"}} ></div>
          </div>
        </div>
      </div>
      {/* feature card stack 2 */}
      <div className="container-fluid feature-card-stack feature-card-stack-second container-padding-right">
        <div className="row position-relative">
          <div className="card card-stackable no-padding card-chart-placeholder position-relative">
            <div className="chart-line-bg">
              <div className="chart-line"></div>
            </div>
            <div className="chart-line-bg">
              <div className="chart-line"></div>
            </div>
            <div className="chart-line-bg">
              <div className="chart-line"></div>
            </div>
          </div>
          <img src={swooshCurve} alt="swoosh curve" />
          <div className="card card-spacey card-stackable">
            <p className="text-muted">
              PROJEKTY
            </p>
            <h3>
              Správa projektů
              na jednom místě
            </h3>
            <p>Taky tě nebaví spravovat SEO projekty na více místech najednou? <b>U nás najdeš všechny metriky</b>, které běžně používáš, na jednom místě - rychle, přehledně a navíc rozšířené o doporučení pro další rozvoj projektu. <b>Dashboardy ti pomohou držet přehled, cíle ti nedovolí sejít z cesty</b>.</p>
            {/* <Link className="btn btn-link btn-link-cta" to="/projects">
              Learn more
              <img src={arrow} alt="arrow open" /> 
            </Link> */}
          </div>
        </div>
      </div>
      {/* our pros */}
      <div className="container-xl section-pros" id="benefits" style={{scrollMarginTop: "120px"}}>
        <div className="row">
          <div className="col-sm-12 col-md-4 text-center">
            <div className="card card-secondary">
              <img src={iconMetrics} alt="icon metrics" />
            </div>
            <h5>
              Kombinované metriky
            </h5>
            <p>
              Všechny metriky, které běžně používáš, na jednom místě bez nutnosti přepínání mezi nástroji
            </p>
          </div>
          <div className="col-sm-12 col-md-4 text-center">
            <div className="card card-secondary">
              <img src={iconProjects} alt="icon projects" />
            </div>
            <h5>
              Dashboardy
            </h5>
            <p>
              Dashboardy ti pomohou udržet každodenní přehled nad tvými projekty.
            </p>
          </div>
          <div className="col-sm-12 col-md-4 text-center">
            <div className="card card-secondary">
              <img src={iconGame} alt="icon game" />
            </div>
            <h5>
              Cíle
            </h5>
            <p>
              Nastav si cíle projektu, vyhodnocuj jejich plnění a vyhraj hru.
            </p>
          </div>
        </div>
      </div>
      {/* section testimonials */}
      <Slider
        className="container-xl section-testimonials"
        id="reference"
        interval={15000}
      >
        <>
          <div className="card card-spacey card-secondary testimonial">
            <StaticImage
              src="../images/skyvarova.jpeg"
              alt="testimonials author"
            />
            <h5>“Konečně jeden nástroj pro všechny naše důležité metriky, který nám dává přesnější obrázek o naší visibilitě.”</h5>
            <p className="text-grayed text-center"><span className="text-grayed name">Helen Škývarová</span> Head of SEO <span className="company">Mall.cz</span></p>
          </div>
          <div className="card card-spacey card-secondary testimonial">
            <StaticImage
              src="../images/hlavac.jpeg"
              alt="testimonials author"
            />
            <h5>“Keyword Eater nám umožňuje být v co největším detailu a zároveň netrávit čas klikáním. Primárně ho využíváme pro získávání dat Share of Voice a sekundárně pro dlouhodobý sběr dat konkurence.”</h5>
            <p className="text-grayed text-center"><span className="text-grayed name">Petr Hlaváč</span> Head of SEO <span className="company">eVisions</span></p>
          </div>
        </>
      </Slider>
      <CtaBlock
        subscribe={nextEmail=>{
          setEmail(nextEmail);
          handleModalToggle()
        }}
      />
      <SocialsBlock />
      <ModalRegister
        isOpen={modalRegister}
        email={email}
        close={handleModalToggle}
      />
      <Footer />
    </main>
  )
}

export default IndexPage
