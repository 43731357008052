import classNames from 'classnames';
import React, { FC, useState } from 'react';

export type ButtonDesign = "primary" | "secondary" | "tertiary" | "cta" | "primary-outline" | "none" | "icon-hover" | "table-click-through" | "link";

interface ButtonProps{
    children?: any;
    onClick?: ()=>void;

    design?: ButtonDesign;
    disabled?: boolean;
    onHoverText?: string;
    weight?: 'normal' | 'light';

    offsetLeft?: 'normal';
    display?: "inline" | "inline-block" | "block";
    type?: "button" | "reset" | "submit";
    className?: string;
}

const Button: FC<ButtonProps> = ({
    children,
    onClick,

    design = "primary",
    display = "inline",
    disabled = false,
    onHoverText,
    weight,

    offsetLeft,
    type = 'button',
    className,
    ...props
}) => {

    const [isHover, setIsHover] = useState<boolean>(false);

    return (
        <button
            className={classNames(
                "px-6 py-2 border-1 border-primary",
                design === "primary" && "bg-primary text-white hover:bg-auxilary hover:text-primary",
                
                
                className && className
            )}
            type={type}
            disabled={disabled}
            onClick={onClick}
            onMouseOver={()=>setIsHover(true)}
            onMouseLeave={()=>setIsHover(false)}
            {...props}
        >
            {(isHover && onHoverText) || children}
        </button>
    );
}
export default Button;