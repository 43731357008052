import React, {FC, useEffect, useState} from 'react';
import "../styles/modal.css";
import iconClose from "../images/icons/Close.svg";
import success from "../images/illustrations/success.svg";
import { apiBaseUri, call, getUri } from '../utils/call/Calls';
import { RequestMethod } from '../enums/RequestMethod';
import Select from '../utils/select/Select';
import { useFormik } from 'formik';
import InputGroup from 'src/utils/inputGroup/InputGroup';
import * as Yup from 'yup';
import { navigate } from 'gatsby';

const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .required('Pole jméno je povinné.'),
    surname: Yup.string()
      .required('Pole příjmení je povinné.'),
    email: Yup.string().email('Zadejte prosím platný email.').required('Pole email je povinné.'),
    phone: Yup.string(),
    company: Yup.string().required("Pole společnost je povinné."),
    position: Yup.string().required("Pole pozice je povinné.")
    // phone: Yup.number().le
  });
 

interface ModalRegisterProps {
  isOpen: boolean;
  close: ()=>void;
  email?: string;
}

const ModalRegister : FC<ModalRegisterProps> = ({
  isOpen,
  close,
  email,
}) => {

    const [animating, setAnimating] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDone, setIsDone] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            phone: "",
            company: '',
            position: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: values => {
            setIsLoading(true);
            call(
                RequestMethod.POST,
                // getUri(`${apiBaseUri()}subscription/`), //dev
                getUri(`${apiBaseUri()}subscription/registration`), //bude prod
                undefined,
                {
                    email: values.email,
                    phone: values.phone,
                    position: values.position,
                    firstName: values.name,
                    lastname: values.surname,
                    company: values.company
                },
                undefined,
                undefined,
                true
            ).then(resp=>{
                if(resp.status === 409){
                    throw new Error("409");
                }
                return resp.json();
            }
            ).then((data:{
                email: string,
                subscriptionAllowed: boolean
            })=>{
                setIsDone(true);
                navigate('?register-success', { replace: true });
                setIsLoading(false);
            }).catch(err=>{
                console.log(err.toString());
                if(err?.toString() === "Error: 409"){
                    window.alert("WOW, vidíme, že máš opravdu zájem. Neboj 2x se registrovat nemusíš, už s tebou počítáme! 💜");
                }else{
                    window.alert("Nastal problém při komunikaci se serverem, zkus to prosím později.");
                }
                setIsLoading(false);
            });
            //odeslání do google ads
            if(typeof window !== undefined){
                window.gtag("event", "click", { send_to: "AW-10886923757/4Xp8CN3BzrQDEO2Lpcco"})
            }
            //konec odeslání do g ads
        },
      });

    useEffect(() => {
      setAnimating(true);
      setInterval(() => setAnimating(false), 2000);
      if(email){
          formik.setFieldValue("email", email);
      }
      if(isOpen){
        //   setIsDone(false);
      }
    }, [isOpen, email])
    

    return (
      <div className={`modal-bg ${animating && "modal-animation"} ${isOpen && "active"}`}>
          <div className='card modal'>
            <button 
                onClick={close}
                className="close border-auxilary hover:border-primary"
            >
                <img src={iconClose} alt="close" />
            </button>
            {isDone && <>
                <img src={success} className="illustration-count" alt="illustration success" />
            </>}
            <h5 className={isDone ? "heading-succes": ""}>
                {isDone ? "Ověř svůj e-mail" : "Registrace = SOV zdarma"}
            </h5>
            {isDone && <p>Na tvoji e-mailovou adresu jsme odeslali ověřovací mail. Potvrď jej, prosím, ve své schránce</p>}
            {!isDone && <p>No worries, jsme markeťáci jako ty, data nesdílíme dál. Pro prvních 150 registrovaných Share of Voice zcela zdarma. 🎉 Změř si SOV zdarma pro 1000 klíčových slov a až 5 konkurentů.</p>}
            {!isDone && <>
                <form onSubmit={formik.handleSubmit} className="submit-form">
                    <div className="card card-outline">
                        <InputGroup
                            formik={formik}
                            name="name"
                            label='Jméno'
                            placeholder='Tvoje jméno'
                            type='text'
                            required
                        />
                        <InputGroup
                            formik={formik}
                            name="surname"
                            label='Příjmení'
                            placeholder='Tvoje příjmení'
                            type='text'
                            required
                        />
                        <InputGroup
                            formik={formik}
                            className="wide"
                            name="email"
                            label='Email'
                            placeholder='Email'
                            type='email'
                            required
                        />
                        <InputGroup
                            formik={formik}
                            name="phone"
                            label='Tel. číslo (doporučeno)'
                            placeholder='Tvoje tel. číslo'
                            type='tel'
                        />
                        <InputGroup
                            formik={formik}
                            name="company"
                            label='Společnost'
                            placeholder='Kde děláš'
                            type='text'
                            required
                        />
                        <div className='input-group wide'>
                            <label htmlFor='position' className='required'>Pozice</label>
                            <Select
                                id="position"
                                name="position"
                                onChange={(selected)=>formik.setFieldValue("position", selected.value)}
                                placeholder="Tvoje pozice"
                                isSearchable={false}
                                optionList={[
                                    {label: "👨‍💻 Konzultant", value: "consultant"},
                                    {label: "🤵‍♂️ Manažer", value: "manager"},
                                    {label: "💆‍♂️ Majitel", value: "owner"},
                                    {label: "🥷 Ostatní", value: "other"},
                                ]}
                            />
                            {formik.touched.position && formik.errors.position ? (
                                <p className="text-sm text-error mt-1">{formik.errors.position}</p>
                            ) : null}
                        </div>
                    </div>
                    <p className='you-shall-not-pass'>Kliknutím na tlačítko "<b>Odeslat formulář</b>" souhlasíte s tím, aby vám společnost Keyword Eater, s.r.o. zaslala obchodní sdělení <b>pouze</b> související se <b>spuštěním aplikace</b>.</p>
                    <button
                        className='btn btn-primary px-6 py-2 border-1 border-primary bg-primary text-white hover:bg-auxilary hover:text-primary mt-6 hover:border-primary'
                        id="register-submit"
                        type="submit"
                    >
                        Odeslat formulář
                        {isLoading && <div className='spinner' />}
                    </button>
                </form>
            </>}
          </div>
      </div>
    )
  }
  export default ModalRegister;