import classNames from 'classnames';
import React, { FC } from 'react';
// import styles from "./Select.module.scss";
import {default as ReactSelect} from 'react-select';
import { Color } from '../../enums/Color';
// import "./Select.scss";

export interface SelectOption{
    value: string | number;
    label: string;
}
interface SelectProps {
    className?: string;
    optionList: Array<SelectOption>;
    onChange: (value:any)=>void;
    optionSelected?: SelectOption;
    optionDefault?: SelectOption;
    isLoading?: boolean;
    isDisabled?: boolean;
    isSearchable?: boolean;
    isFullWidth?: boolean;
    placeholder?: string;
}

const Select: FC<SelectProps> = ({
    className,
    optionList,
    onChange,
    optionSelected,
    optionDefault,
    isLoading,
    isDisabled = false,
    isSearchable = true,
    isFullWidth,
    placeholder,
    ...props
}) => {
  return(
    <div className={classNames(
        // styles.Select_container,
        // isFullWidth && styles.Select__fullWidth,
        className && className
    )}>
        <ReactSelect
            // className={styles.SelectSearch}
            classNamePrefix="select"
            // classNamePrefix={styles.Select}
            // className={styles.Select}
            placeholder={placeholder}
            isDisabled={isLoading || isDisabled}
            isSearchable={isSearchable}
            name="selector"
            options={optionList}
            noOptionsMessage={({inputValue}) => !inputValue ? "Nic tu není" : "To, co tu hledáš, tu není, ale mohlo by, ale stejně není 😭 ... ale můžeš zkusit něco jiného."} 
            defaultValue={optionDefault}
            value={optionSelected}
            onChange={onChange}
            theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#EFECFA',
                  primary: '#EFECFA',
                },
              })}
            styles={customStyles}
            {...props}
        />
    </div>
    
  );
}
export default Select;

const customStyles = {
    option: (provided:any, state:any) => ({
        ...provided,
        color: "#2D0E6B",
        padding: '8px',
        textAlign: 'center',
        textAlignLast: 'center',
        cursor: 'pointer',
        fontWeight: state.isSelected? 'bold' : 'normal',
    }),
    container: (provided: any)=>({
        ...provided,
    }),
    control: (provided:any, styles:any) => ({
        ...provided,
        cursor: "pointer",
        background: Color.auxilary,
        flexWrap: 'nowrap',
        zIndex: 2,
        justifyContent: 'center',
        borderRadius: "100px",
        border: `1px solid ${Color.auxilary}`,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#2D0E6B",
        padding: "8px 32px",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.06)",
        transition: "border-radius .3s ease",
        '&:hover:not(.select__control--menu-is-open)':{
            borderColor: Color.primary,
        },
        '&.select__control--menu-is-open':{
            borderRadius: "19px 19px 0 0",
            borderColor: 'red',
            border: `1px solid ${Color.white}`,
            backgroundColor: '#FFF',
            color: "#2D0E6B",
        }
    }),
    input: (provided:any)=>({
        ...provided,
        margin: 0,
        padding: 0
    }),
    valueContainer: (provided:any)=>({
        ...provided,
        flex: 'unset',
        width: "min-content",
        whiteSpace: "nowrap"
    }),
    placeholder: (provided:any) =>({
        ...provided,
        color: "#2D0E6B"
    }),
    menuPortal: (provided:any)=>({
        ...provided,
    }),
    menu: (provided:any)=>({
        ...provided,
        overflow:"hidden",
        // marginTop: 0,
        borderRadius: "19px 19px",
        paddingTop: '40px',
        top: '0',
        // paddingTop: "43px",
        zIndex: 1
    }),
    dropdownIndicator: (provided: any) =>({
        ...provided,
        color: Color.primary,
        padding: 0,
        svg:{
            width: '16px',
            height: '16px',
        }
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none'
    }),
    singleValue: (provided:any, state:any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
  
      return {
            ...provided, 
            opacity, 
            transition,
            color: "#2D0E6B",
            fontWeight: 600,
            whiteSpace: "nowrap",
            width: "min-content"
        };
    }
  }